
import Vue from 'vue';
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';
import Status from '@/models/onboarding/Status';
import BoxTarget from '@/components/Box/Target/BoxTarget.vue';
import { checkPermission } from '@/api/utils';
import { addKpiCustomization } from '@/util/kpis';
import ProfileSurvey from "@/components/modals/SurveyModel.vue"
import moment from "moment";
import axios from 'axios';
import debounce from 'lodash/debounce';
import store from '@/store'

export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { ProfileSurvey },
  data() {
    return {
      coronaBannerReadStatus: true,
      lastTimeStamp: Date.now(),
      lastDateUploaded: '',
      onboardingStatus: Status.DONE,
      overviewLoading: false,
      boxTargetLoading: false,
      comparisionLoading: false,
      overViewKpiList: [],
      apiTimer: 0,
      lastModifiedJsonData: {},
      hasFetchedPref: false,
      apiTimeout: 0,
      canShowSurvey: false
    };
  },
  computed: {
    ...mapGetters('tenant', ['hasResetAccount', 'isNewAccount']),
    ...mapState('overview', { overViewKpis: 'kpis' }),
    ...mapState('overview', [ 'regions', 'districts', 'stores', 'groups', 'SVs', 'allTrends' ]),
    ...mapState('user', ['kpiPreferences', 'kpiLabelValue', 'getLocale']),
    ...mapGetters('filter', [
      'getStoreCodesList',
      'getDaysOfWeekList',
      'getStartingPeriod',
      'getEndingPeriod',
      'getStartingCompared',
      'getEndingCompared',
      'getCustomCalenderState',
      'getCustom445DateRange'
    ]),

    // ...mapState('comparison', ['regions', 'districts', 'stores', 'groups', 'SVs']),
    ...mapState('target', ['targets', 'callTracker'])
  },
  watch: {
    kpiPreferences(newValue) {
      this.overViewKpiList = this.kpis && addKpiCustomization(this.kpis);
    },
    getStartingPeriod:{
        handler(newQuestion) {
        this.refreshAction()
      },
      immediate: true
    },
    // getStartingPeriod(newValue, oldValue) {
    //   console.log("getStartingPeriod watcher", newValue, oldValue)
    // },
  },
  mounted() {
    let surveyEmailAllowed = ["testinguser@flow-solutions.com", "shuhei@flow-insight.com"]
    this.canShowSurvey = surveyEmailAllowed.indexOf(store.state.user.profile.email) > -1;
    Vue.prototype?.$mixpanel?.track("dashboard", {
      referrer: document.referrer,
    });

  },
  // async created() {
    // const tenentIDD = localStorage.getItem('tenentID');
    // if(tenentIDD == 'hDnCfC55HomBqRbM5'
    // || tenentIDD == 'YEgaiFKJ3rt4d9q4g'
    // || tenentIDD == 'AmPwMfg3vK5cgWPQP'
    // || tenentIDD == '4TxrckSM8PrcEvfx9'
    // || tenentIDD == 'EGinM2LhX2gQmjtSz'
    // || tenentIDD == 'k2dizAofphvMY5Wzr'
    // || tenentIDD == 'PS2EaDKummN4GxNPP'
    // )//stripe
    // {
    //   localStorage.setItem('fidaMigrationCheck','true');
    // }
    // this.refreshPage()
  // },
  methods: {
    ...mapActions('comparison', ['fetchAllComparision']),
    ...mapActions('overview', ['fetchOverview', 'setDataByLocationFormatKey']),
    ...mapActions('target', ['fetchTargets', 'fetchTargetsSales']),
    ...mapActions("weather", ["getWeather"]),
    ...mapActions('user', ['fetchKpiPreferences']),
    async refreshAction() {
      clearTimeout(this.apiTimeout)
      const _this = this;
      this.apiTimeout = setTimeout(async function() {
        await _this.fetchKpiPreferences()
        setTimeout(async () => {
          _this.lastTimeStamp = Date.now();
          _this.refreshOverview();
          _this.refreshBoxTarget();
          _this.lastDateUploaded = await _this.calcLastTimeStamp()
          _this.hasFetchedPref = true
        }, _this.hasFetchedPref ? 0 : 1000)
      }, 500)
    },
    async refreshPage() {
      clearTimeout(this.apiTimer)
      this.apiTimer = setTimeout(this.refreshAction, 200)
    },
    refreshPageComparision(val) {
      this.setDataByLocationFormatKey({key: val.value})
    },
    getParamsForOverview() {
      let stC = this.getStoreCodesList;
      if(!stC) {
        return;
      }
      return {
          startDate: this.getStartingPeriod,
          endDate: this.getEndingPeriod,
          compareStartDate: this.getStartingCompared,
          compareEndDate: this.getEndingCompared,
          storeCodes: stC,
          daysOfWeek: this.getDaysOfWeekList,
          useCustomCalender: this.getCustomCalenderState,
          groupByMetricKey: true,
          comparisonYKPIMetricKey: ([...this.kpiLabelValue][0] ?? {})?.value
          // kpis: this.customSort.join(',')
        };
    },
    async refreshOverview() {
      try {

        this.overviewLoading = true;

        let params = this.getParamsForOverview();
        if(params) {
          let str = JSON.stringify(params);

          params.trackLatest = { callerId: "home", value: str }
          console.log('paramsHome',params);
          let isActionable = await this.fetchOverview(params);
          console.log('this.overViewKpis',this.overViewKpis);
          if(isActionable) {
            this.overViewKpiList = addKpiCustomization(this.overViewKpis);
            this.overviewLoading = false;
          }
          console.log('this.overViewKpis2',this.overViewKpiList);
        } else {
          setTimeout(this.refreshOverview, 1000)
        }
      } catch(e) {

        this.$q.notify({
          type: 'negative',
          position: 'top',
          message: `${e}`
        });
      }
    },
    async refreshBoxTarget() {
      try {

        this.boxTargetLoading = true;
        let stC = this.getStoreCodesList;
        if(!stC) {
          setTimeout(this.refreshBoxTarget, 1000)
          return;
        }
        let params = {
          startDate: this.getStartingPeriod,
          endDate: this.getEndingPeriod,
          kpiTypes: ["sls", "st"],
          storeCodes: stC.split(","),
        };
        let str = JSON.stringify(params)
        params.trackLatest = { callerId: "home", value: str }
        await this.fetchTargets(params);
      } catch (error) {
        this.$q.notify({
          type: 'negative',
          position: 'top',
          message: `${error}`
        });
      } finally {
        this.boxTargetLoading = false;
      }
    },
    async calcLastTimeStamp() {
      const apiUrl =
        'https://flow-public-resource.s3.ap-northeast-1.amazonaws.com/data/LastModifiedStore.json';
      const config = {
        headers: {
          Authorization: ''
        }
      };
      return JSON.stringify(await axios.get(apiUrl, config))
    },
  }
};
